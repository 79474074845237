/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import "./styles/app.scss";
import "./styles/variables.scss";

// start the Stimulus application
import "./bootstrap";

var header = document.querySelector(".js-header");
var navbarToggler = document.querySelector(".js-navbar-toggler");
var navbarCollapse = document.querySelector(".navbar-collapse");

window.addEventListener("scroll", function () {
    var scrollPosition = window.scrollY;

    if (header) {
        if (scrollPosition > 450) {
            header.classList.add("scrolled");
        } else {
            header.classList.remove("scrolled");
        }

        navbarCollapse.classList.remove("show");
        header.classList.remove("active");
        navbarToggler.ariaExpanded = "false";
    }
});

navbarToggler.addEventListener("click", function () {
    if (header) {
        if (navbarToggler.classList.contains("collapsed")) {
            header.classList.remove("active");
        } else {
            header.classList.add("active");
        }
    }
});

document.addEventListener("click", function (event) {
    var isClickInsideMenu = header.contains(event.target);

    if (header) {
        if (!isClickInsideMenu) {
            navbarCollapse.classList.remove("show");
            header.classList.remove("active");
            navbarToggler.ariaExpanded = "false";
        }
    }
});
